import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAccountContext } from 'src/context'
import { routes } from 'src/config'

const Home: React.FC = () => {
    const { authorized } = useAccountContext()

    return authorized ? (
        <Navigate replace to={routes.dashboard} />
    ) : (
        <Navigate replace to={routes.login} />
    )
}

export default Home
