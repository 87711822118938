import React from 'react'
import { Box, Typography } from '@mui/material'

interface IFootNote {
    centered?: boolean
}

const FootNote: React.FC<React.PropsWithChildren<IFootNote>> = ({ centered, children }) => {
    return (
        <Box
            sx={{
                my: 1,
                mx: {
                    xs: centered ? 'auto' : 0,
                    md: 0
                },
                maxWidth: {
                    xs: centered ? '25rem' : '90%',
                    md: '75%'
                }
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Typography
                    variant="body2"
                    sx={{
                        color: 'rgba(255, 255, 255, 0.75)',
                        fontWeight: 400,
                        lineHeight: 1,
                        fontSize: '1rem'
                    }}
                >
                    *
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        ml: 1,
                        color: 'rgba(255, 255, 255, 0.75)',
                        fontWeight: 400,
                        lineHeight: 1.25,
                        fontSize: '0.75rem'
                    }}
                >
                    {children}
                </Typography>
            </Box>
        </Box>
    )
}

export default FootNote
