import React from 'react'

const PurchaseMicrosoftLogo: React.FC = () => {
    return (
        <svg
            width="115"
            height="32"
            viewBox="0 0 115 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_22_2261)">
                <path
                    d="M47.5335 23.17H44.5611L41.6627 12.1626L38.7323 23.17H35.6942L31.709 8.86255H34.405L37.2789 20.2432L40.4163 8.86255H43.1395L46.0886 20.3107L48.818 8.86255H51.4213L47.5335 23.17Z"
                    fill="white"
                />
                <path
                    d="M55.2319 9.90899C55.2319 10.2958 55.0891 10.6181 54.8034 10.876C54.5246 11.1338 54.1845 11.2628 53.7832 11.2628C53.3819 11.2628 53.0418 11.1338 52.763 10.876C52.4842 10.6181 52.3447 10.2958 52.3447 9.90899C52.3447 9.51542 52.4842 9.18632 52.763 8.92163C53.0487 8.65699 53.3888 8.52466 53.7832 8.52466C54.1981 8.52466 54.5416 8.6604 54.8137 8.93181C55.0925 9.20326 55.2319 9.52896 55.2319 9.90896L55.2319 9.90899ZM52.5429 23.1694V12.8585H54.9522V23.1694H52.5429Z"
                    fill="white"
                />
                <path
                    d="M64.2251 23.17V17.4292C64.2251 15.925 63.7575 14.6275 62.2387 14.6275C60.7278 14.6275 59.7698 15.9848 59.7698 17.3579V23.1698H57.4131V12.8589H59.7698V14.3174H59.8106C60.5927 13.1093 61.7218 12.6851 63.1977 12.6851C64.3063 12.6851 65.1633 12.9573 65.7686 13.663C66.3807 14.3688 66.6494 15.4409 66.6494 16.8795V23.1696L64.2251 23.17Z"
                    fill="white"
                />
                <path
                    d="M76.0591 23.17V21.8426H76.0182C75.2701 23.098 73.7429 23.3875 72.6782 23.3875C69.5138 23.3875 68.3525 20.9349 68.3525 18.325C68.3525 16.5946 68.7844 15.2103 69.6482 14.1721C70.5188 13.127 71.6818 12.6747 73.1373 12.6747C75.4031 12.6747 76.0183 13.9325 76.0183 13.9325H76.0591V7.96533H78.5037V23.1701L76.0591 23.17ZM76.0795 17.2945C76.0795 16.049 75.2772 14.6339 73.584 14.6339C71.647 14.6339 70.8943 16.3156 70.8943 18.2539C70.8943 19.9461 71.6046 21.482 73.4491 21.5075C75.2578 21.5075 76.0648 19.7876 76.0795 18.3659V17.2945Z"
                    fill="white"
                />
                <path
                    d="M90.8361 17.9689C90.8361 21.5404 88.6967 23.4751 85.4838 23.4751C81.9901 23.4751 80.1992 21.3884 80.1992 18.1927C80.1992 14.5369 82.3451 12.6353 85.7388 12.6353C88.8484 12.6353 90.8361 14.6184 90.8361 17.9689ZM88.3263 18.0503C88.3263 16.4613 87.7618 14.6951 85.6061 14.6951C83.5386 14.6951 82.7354 16.2301 82.7354 18.1114C82.7354 20.1551 83.7001 21.455 85.5914 21.455C87.6175 21.455 88.3116 19.9035 88.3263 18.0503H88.3263Z"
                    fill="white"
                />
                <path
                    d="M103.767 23.1699H101.108L99.0753 15.7076L96.8949 23.1699H94.3141L91.3076 12.8906H93.8307L95.7941 20.8765L98.0888 12.8906H100.455L102.501 20.8558L104.415 12.8906H106.728L103.767 23.1699Z"
                    fill="white"
                />
                <path
                    d="M114.296 20.2998C114.296 22.5954 112.21 23.4901 110.118 23.4901C108.756 23.4901 107.4 22.9666 107.4 22.9666V20.5249C107.4 20.5249 108.579 21.5499 110.226 21.5627C111.355 21.5627 111.863 21.2075 111.863 20.5312C111.891 19.9886 111.56 19.6894 111.164 19.4651C110.892 19.3022 110.467 19.1021 109.889 18.8645C109.209 18.5728 108.702 18.2911 108.369 18.0197C108.042 17.7483 107.797 17.4294 107.634 17.0629C107.478 16.6965 107.362 16.2938 107.362 15.8052C107.362 13.9516 108.912 12.6418 111.389 12.6418C113.026 12.6418 113.735 13.0423 113.735 13.0423V15.362C113.735 15.362 112.572 14.5598 111.363 14.5525C110.497 14.5525 109.842 14.8671 109.83 15.5791C109.824 16.4969 110.951 16.9442 111.623 17.2157C112.603 17.6093 113.319 18.0294 113.721 18.5112C114.122 18.993 114.296 19.4782 114.296 20.2999L114.296 20.2998Z"
                    fill="white"
                />
                <path d="M11.7369 4.00024H0.320312V15.3907H11.7369V4.00024Z" fill="white" />
                <path d="M24.4059 4.00024H12.9893V15.3907H24.4059V4.00024Z" fill="white" />
                <path d="M11.7369 16.6414H0.320312V28.0318H11.7369V16.6414Z" fill="white" />
                <path d="M24.4059 16.6414H12.9893V28.0318H24.4059V16.6414Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_22_2261">
                    <rect width="114" height="32" fill="white" transform="translate(0.160156)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default PurchaseMicrosoftLogo
