import React from 'react'
import { Box, SxProps } from '@mui/material'

interface IDecoratedCorners {
    size: number
    thickness: number
    color: string
    sx?: SxProps
}

const DecoratedCorners: React.FC<React.PropsWithChildren<IDecoratedCorners>> = ({
    size,
    thickness,
    color,
    sx,
    children
}) => {
    return (
        <Box sx={{ position: 'relative', ...sx }}>
            <Box sx={{ position: 'relative', zIndex: 1 }}>{children}</Box>
            <Box
                sx={{
                    position: 'absolute',
                    top: -thickness,
                    left: -thickness,
                    width: size,
                    height: size,
                    borderTopStyle: 'solid',
                    borderTopWidth: thickness,
                    borderTopColor: color,
                    borderLeftStyle: 'solid',
                    borderLeftWidth: thickness,
                    borderLeftColor: color
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: -thickness,
                    right: -thickness,
                    width: size,
                    height: size,
                    borderTopStyle: 'solid',
                    borderTopWidth: thickness,
                    borderTopColor: color,
                    borderRightStyle: 'solid',
                    borderRightWidth: thickness,
                    borderRightColor: color
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: -thickness,
                    left: -thickness,
                    width: size,
                    height: size,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: thickness,
                    borderBottomColor: color,
                    borderLeftStyle: 'solid',
                    borderLeftWidth: thickness,
                    borderLeftColor: color
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: -thickness,
                    right: -thickness,
                    width: size,
                    height: size,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: thickness,
                    borderBottomColor: color,
                    borderRightStyle: 'solid',
                    borderRightWidth: thickness,
                    borderRightColor: color
                }}
            />
        </Box>
    )
}

export default DecoratedCorners
