import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faSteam, faWindows, faXbox } from '@fortawesome/free-brands-svg-icons'
import { DecoratedCorners } from 'src/components'
import { useAccountContext } from 'src/context'
import { routes } from 'src/config'

interface IPlatform {
    icon: IconDefinition
    title: string
    href: string
}

const Platform: React.FC<IPlatform> = ({ icon, title, href }) => {
    return (
        <Box m={{ xs: 2, sm: 4 }} sx={{ textAlign: 'center' }}>
            <Box
                component={Link}
                to={href}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                    borderWidth: 3,
                    borderStyle: 'solid',
                    borderColor: 'secondary.light',
                    color: 'common.white',
                    height: { xs: '7rem', sm: '8rem' },
                    width: { xs: '7rem', sm: '8rem' },
                    transition: 'all .3s ease-in-out',
                    '&:hover': {
                        backgroundColor: 'secondary.dark',
                        borderColor: 'primary.light'
                    }
                }}
            >
                <FontAwesomeIcon icon={icon} size="4x" />
            </Box>
            <Typography
                mt={1}
                variant="body1"
                color="primary.light"
                sx={{
                    fontSize: { xs: 'body2.fontSize', sm: 'body1.fontSize' }
                }}
            >
                {title}
            </Typography>
        </Box>
    )
}

const PlatformLogin: React.FC = () => {
    const navigate = useNavigate()
    const { authorized } = useAccountContext()

    React.useEffect(() => {
        if (authorized) {
            navigate(routes.dashboard, { replace: true })
        }
    })

    return (
        <DecoratedCorners size={12} thickness={3} color="primary.light">
            <Box
                sx={{
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'primary.light',
                    backgroundColor: 'common.black'
                }}
            >
                <Box
                    sx={{
                        px: 2,
                        pt: 1,
                        pb: 1.5,
                        lineHeight: 1.15,
                        fontWeight: 500,
                        color: 'common.black',
                        backgroundColor: 'primary.light',
                        fontFamily: 'body1.fontFamily',
                        textAlign: 'center',
                        fontSize: {
                            xs: 'body2.fontSize',
                            sm: 'body1.fontSize',
                            md: 'h6.fontSize'
                        }
                    }}
                >
                    Use your game platform to sign in
                </Box>
                <Box
                    my={2}
                    pt={1}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}
                >
                    <Platform icon={faSteam} title="Steam" href={routes.steamLogin} />
                    <Platform icon={faWindows} title="Windows PC" href={routes.xboxLogin} />
                    <Platform icon={faXbox} title="Xbox" href={routes.xboxLogin} />
                </Box>
            </Box>
        </DecoratedCorners>
    )
}

export default PlatformLogin
