import React from 'react'
import Authorized from './Authorized'
import { Route, Routes as Switch, useLocation } from 'react-router-dom'
import { routeComponents, routes } from 'src/config'

const Routes: React.FC = () => {
    const location = useLocation()

    return (
        <Switch location={location}>
            {routeComponents.map((route) => (
                <Route
                    key={route.path}
                    path={routes[route.path]}
                    element={<Authorized route={route} />}
                />
            ))}
        </Switch>
    )
}

export default Routes
