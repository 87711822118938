import React from 'react'

const PurchaseSteamLogo: React.FC = () => {
    return (
        <svg
            width="102"
            height="32"
            viewBox="0 0 102 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_22_2247)">
                <path
                    d="M15.3212 0C7.24699 0 0.629115 6.48174 0 14.7178L8.24105 18.2625C8.93968 17.7669 9.7843 17.4739 10.6915 17.4739C10.7714 17.4739 10.8548 17.4739 10.9348 17.4812L14.5982 11.9543V11.8748C14.5982 8.54708 17.1981 5.84153 20.3958 5.84153C23.5935 5.84153 26.1934 8.54708 26.1934 11.8748C26.1934 15.2024 23.5935 17.908 20.3958 17.908C20.3506 17.908 20.3089 17.908 20.2637 17.908L15.0362 21.7891C15.0362 21.8578 15.0397 21.9301 15.0397 22.0025C15.0397 24.5019 13.0863 26.531 10.688 26.531C8.58168 26.531 6.81946 24.9648 6.41975 22.8923L0.528317 20.3603C2.3531 27.0772 8.28276 32 15.3212 32C23.8125 32 30.698 24.8346 30.698 15.9982C30.698 7.16175 23.8125 0 15.3212 0Z"
                    fill="white"
                />
                <path
                    d="M9.63441 24.2775L7.74707 23.4637C8.08074 24.1871 8.6612 24.7947 9.42934 25.1311C11.0908 25.8509 13.0094 25.0298 13.7011 23.3009C14.0382 22.4653 14.0382 21.5394 13.7045 20.7002C13.3709 19.8611 12.7452 19.2064 11.9423 18.8555C11.1429 18.5083 10.2879 18.5228 9.53362 18.8194L11.4835 19.6585C12.7105 20.1902 13.2874 21.6551 12.7765 22.9319C12.2656 24.2088 10.8579 24.8128 9.63094 24.2775"
                    fill="white"
                />
                <path
                    d="M24.2614 11.8784C24.2614 9.66111 22.527 7.8562 20.3963 7.8562C18.2657 7.8562 16.5312 9.66111 16.5312 11.8784C16.5312 14.0956 18.2657 15.8969 20.3963 15.8969C22.527 15.8969 24.2614 14.092 24.2614 11.8784ZM17.501 11.8711C17.501 10.2037 18.8009 8.85089 20.4033 8.85089C22.0056 8.85089 23.3055 10.2037 23.3055 11.8711C23.3055 13.5386 22.0056 14.8914 20.4033 14.8914C18.8009 14.8914 17.501 13.5386 17.501 11.8711Z"
                    fill="white"
                />
                <path
                    d="M47.1176 10.9488L46.0888 12.8333C45.2963 12.2546 44.2188 11.9074 43.2804 11.9074C42.2063 11.9074 41.5425 12.3703 41.5425 13.1986C41.5425 14.2042 42.7208 14.4393 44.476 15.094C46.3599 15.7884 47.4443 16.6023 47.4443 18.3963C47.4443 20.8523 45.5883 22.234 42.9189 22.234C41.6189 22.234 40.0479 21.8832 38.8418 21.12L39.5926 19.0293C40.5727 19.5683 41.7441 19.8866 42.7903 19.8866C44.198 19.8866 44.8688 19.3476 44.8688 18.5483C44.8688 17.6331 43.8469 17.3582 42.1994 16.7904C40.3225 16.1393 39.0191 15.2821 39.0191 13.2927C39.0191 11.0501 40.743 9.76245 43.2247 9.76245C44.9557 9.76245 46.346 10.3339 47.1176 10.9416"
                    fill="white"
                />
                <path
                    d="M52.7485 22.0278V12.1388H49.2275V9.97217H58.6851V12.1388H55.1746V22.0278H52.7485V22.0278Z"
                    fill="white"
                />
                <path
                    d="M68.4383 14.8479V17.0146H63.7947V19.8431H69.1821V22.0278H61.3721V9.97217H69.1821V12.1315H63.7947V14.8479H68.4383V14.8479Z"
                    fill="white"
                />
                <path
                    d="M74.5966 19.6876L73.8284 22.0278H71.2842L75.6254 9.97217H78.0654L82.5318 22.0242H79.9041L79.122 19.6839H74.5931L74.5966 19.6876ZM76.8385 12.8513L75.257 17.6765H78.4547L76.8385 12.8513V12.8513Z"
                    fill="white"
                />
                <path
                    d="M90.4464 21.6769L87.3286 14.696V22.0278H85.0068V9.97217H87.3252L91.218 18.6748L94.9719 9.97217H97.3111V22.0278H94.9893V14.6345L91.8194 21.6769H90.4499H90.4464Z"
                    fill="white"
                />
                <path
                    d="M102 11.495C102 12.5294 101.253 13.1769 100.398 13.1769C99.5428 13.1769 98.792 12.5331 98.792 11.495C98.792 10.4569 99.5393 9.81665 100.398 9.81665C101.256 9.81665 102 10.4569 102 11.495ZM99.0596 11.495C99.0596 12.3631 99.6609 12.9056 100.398 12.9056C101.135 12.9056 101.729 12.3594 101.729 11.495C101.729 10.6305 101.131 10.0879 100.398 10.0879C99.6644 10.0879 99.0596 10.6305 99.0596 11.495ZM100.419 10.6377C100.836 10.6377 100.975 10.8656 100.975 11.1152C100.975 11.343 100.846 11.495 100.69 11.5709L101.062 12.2979H100.756L100.443 11.6541H100.12V12.2979H99.866V10.6413H100.422L100.419 10.6377ZM100.12 11.4082H100.401C100.585 11.4082 100.693 11.2888 100.693 11.1369C100.693 10.985 100.617 10.8873 100.401 10.8873H100.12V11.4082Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_22_2247">
                    <rect width="102" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default PurchaseSteamLogo
