import React from 'react'
import { Box } from '@mui/material'

import grid from 'src/assets/grid.png'
import speckle from 'src/assets/speckle.png'

const Backdrop: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <Box
            pt={{ xs: 8, sm: 10, md: 14 }}
            component="main"
            sx={{
                zIndex: 1,
                position: 'relative',
                overflow: 'hidden',
                minHeight: '100%',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    width: '200%',
                    height: '200%',
                    top: '-40px',
                    left: '-40px',
                    zIndex: -1,
                    backgroundImage: `url(${speckle})`,
                    backgroundSize: '80px',
                    filter: 'invert()'
                },
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    width: '200%',
                    height: '200%',
                    top: '-40px',
                    left: '-40px',
                    zIndex: -1,
                    backgroundImage: `url(${grid})`,
                    backgroundSize: '80px',
                    filter: 'blur(1px)'
                }
            }}
        >
            {children}
        </Box>
    )
}

export default Backdrop
