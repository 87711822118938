import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Box, Typography, useTheme } from '@mui/material'

import PurchaseMicrosoftLogo from './PurchaseMicrosoftLogo'
import PurchaseSteamLogo from './PurchaseSteamLogo'

import bg from 'src/assets/darktide-banner.png'
import logo from 'src/assets/darktide-logo.png'

function svgToString(svg: React.ReactNode): string {
    const svgString = encodeURIComponent(renderToStaticMarkup(svg))
    return `data:image/svg+xml,${svgString}`
}

interface IVendorLink {
    href: string
    src: string
    alt: string
}

const VendorLink: React.FC<IVendorLink> = ({ href, src, alt }) => {
    const theme = useTheme()

    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            style={{
                backgroundColor: 'transparent',
                paddingTop: 0,
                paddingBottom: 0,
                color: theme.palette.common.white,
                textDecoration: 'none'
            }}
        >
            <img
                src={src}
                alt={alt}
                loading="lazy"
                style={{
                    border: 0,
                    maxWidth: '100%',
                    verticalAlign: 'middle',
                    display: 'inline-block'
                }}
            />
        </a>
    )
}

const PromotionBanner: React.FC = () => {
    return (
        <Box
            sx={{
                position: 'relative',
                background: 'common.black',
                paddingBottom: '56.25%',
                minHeight: '36rem',
                height: 0
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    background: `url(${bg}) center center / cover no-repeat`
                }}
            >
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Box
                        sx={{
                            mb: '3%',
                            p: 2,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                pb: 2,
                                width: '100%',
                                maxWidth: { xs: '16rem', sm: '20rem', md: '28rem', lg: '32rem' }
                            }}
                        >
                            <img
                                src={logo}
                                width="100%"
                                alt="Darktide Logo"
                                style={{
                                    userSelect: 'none',
                                    pointerEvents: 'none'
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'row'
                            }}
                        >
                            <VendorLink
                                href="https://store.steampowered.com/app/1361210/Warhammer_40000_Darktide/?utm_campaign=general&utm_content=preorder&utm_medium=web&utm_source=homepage"
                                src={svgToString(<PurchaseSteamLogo />)}
                                alt="Purchase on Steam"
                            />
                            <Box
                                sx={{
                                    width: '1px',
                                    height: 40,
                                    flewGrow: 0,
                                    bgcolor: 'common.white',
                                    mx: {
                                        xs: 2,
                                        sm: 4
                                    }
                                }}
                            />
                            <VendorLink
                                href="https://www.microsoft.com/en-us/p/warhammer-40-000-darktide/9pcts77msz3f"
                                src={svgToString(<PurchaseMicrosoftLogo />)}
                                alt="Purchase on Windows Store"
                            />
                        </Box>
                        <Box sx={{ color: 'common.white', mt: 4, mb: 1 }}>
                            <Typography
                                variant="h4"
                                align="center"
                                sx={{
                                    lineHeight: 1,
                                    fontWeight: 600,
                                    fontSize: '2.25rem',
                                    textTransform: 'uppercase'
                                }}
                            >
                                Play it now
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default PromotionBanner
